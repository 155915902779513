import React from 'react';
import { Navbar, Nav }  from 'react-bootstrap'; 

class Header extends React.Component {
    constructor(props)
    {
      super(props);
      
    }
    render() {
      return <>
        <Navbar bg="dark" variant="dark" fixed="top">
            <img height="64px" src="./bineral-logo.png" />
            <Nav defaultActiveKey="/" as="ul">
                <Nav.Item as="li">
                    <Nav.Link href="/">Bineral</Nav.Link>
                </Nav.Item>

                <Nav.Item as="li">
                    <Nav.Link href="/services" eventKey="link-1">Storitve</Nav.Link>
                </Nav.Item>

                <Nav.Item as="li">
                    <Nav.Link href="/products" eventKey="link-1">Produkti</Nav.Link>
                </Nav.Item>

                <Nav.Item as="li">
                    <Nav.Link href="/contact" eventKey="link-2">Kontakt</Nav.Link>
                </Nav.Item>
            </Nav>
        </Navbar>
      </>
    }
}
export default Header;