import React from 'react';
//import { Placeholder, Navbar, Nav }  from 'react-bootstrap'; 
import { Container }  from 'react-bootstrap'; 

class Home extends React.Component {
    constructor(props)
    {
      super(props);
      
    }
    render() {
      return <Container fluid="xs" className="mainPage">
               <h1>BINERAL</h1>
               <p>Bineral je bil ustanovljen z namenom za izdelave storitev obdelave podatkov. Razvijamo obsežen portfelj storitev, od dolgoročnih pogodb o storitvah do posameznih storitev, kot so usposabljanje, analize, inženiring in svetovanje.</p> 
               <p>Skupaj s strankami in partnerji uvajamo najsodobnejše digitalne tehnologije za storitve na daljavo, orodja za obdelavo podatkov, predvidevanja s pomočjo umetne inteligence in druge rešitve.</p>

               <Container className="subPage">
                <h3>Izboljšajte odločitve</h3>
                <p>Na podlagi vaših podatkov si z našo pomočjo lahko izboljšajte vaše odločitve v poslovanju.</p>
               </Container>
               {/* <img src="./bineral-logo.png" /> */}

            </Container>
    }
}
export default Home;