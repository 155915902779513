import React from 'react';
import { Placeholder, Navbar, Nav }  from 'react-bootstrap'; 
import { Container }  from 'react-bootstrap'; 

class Products extends React.Component {
    constructor(props)
    {
      super(props);
      
    }
    render() {
      return <Container fluid="xs" className="mainPage">
                <h1>PRODUKTI</h1>
               <p>Učinkovite in preproste rešitve za zapletene tokove. Z našimi rešitvami lahko uvedete in vzdržujete klasifikator strojnega učenja brez težav in uporabite napovedi pri svojih odločitvah.</p> 


                <Container className="subPage">
                    <h3>Klasifikacija v načinu na zahtevo ali stalno odkrivanje</h3>
                    <p>Poveča stopnjo avtomatizacije v primerih uporabe, kot so napovedovanje podrobnosti o 
                       računih za nakup, razvrščanje vstopnic za storitve za stranke ali napovedovanje oznak izdelkov.</p>

                    <ul>
                        <li><strong>Klasifikacija problemov</strong></li>
                        <li><strong>Priporočila izdelkov</strong></li>
                        <li><strong>Zaznavanje povečanja prodaje</strong></li>
                        <li><strong>Segmentacija strank</strong></li>
                        <li><strong>Klasifikacija in razvrščanje slik</strong></li>
                    </ul>
                </Container>

                <br/>

                <Container className="subPage">
                    <h3>Napovedovanje</h3>
                    Napovedovanje prihodnosti na podlagi zbranih zgodovinskih podatkov.
                    <ul>
                        <li><strong>Predikcije porabe</strong></li>

                        <li><strong>Zaznavanja konic</strong></li>

                        <li><strong>Napovedovanje konic</strong></li>

                        <li><strong>Napovedovanje prodaje</strong></li> 

                        <li><strong>Napovedovanje povpraševanja</strong></li> 
                    </ul>
                </Container>

                <br/>
                <p>Naše storitve strojnega učenja ponujajo napredne algoritme za pomoč organizacijam pri reševanju ključnih poslovnih izzivov, omogočajo odločanje na podlagi podatkov in ustvarjajo inovativne poslovne modele. 
                <p>Pomagamo vam raziskati možnosti strojnega učenja za rast vašega podjetja in nato ustvariti najsodobnejše rešitve, ki bodo v celoti izkoristile. Imamo znanje pri izvajanju strojnega učenja za odkrivanje anomalij, napovedovanje prihodnosti, mnenja, priporočila za izdelke in še veliko več.</p>

                </p>
                {/* <p>Ne glede na to, ali obdelujete velike količine neobdelanih podatkov ali ste vključeni v majhne količine podatkov, veste, da sta pretvorba, obdelava podatkov in izdelava predikcijskih modelov ključnega pomena za vaše podjetje.
                  Z naraščajočimi plačami osebja, režijskimi stroški in infrastrukturnimi obremenitvami bi lahko bila vaša rešitev, da svoje potrebe po obdelavi podatkov in izdelavi predikcijskih modelov prepustite nam.</p>  */}

            </Container>
    }
}
export default Products;

