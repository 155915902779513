import React from 'react';
import { Placeholder, Navbar, Nav }  from 'react-bootstrap'; 

class Footer extends React.Component {
    constructor(props)
    {
      super(props);
      
    }
    render() {
      return <>
            <Navbar bg="dark" variant="dark" fixed="bottom" className="justify-content-center" placeholder="small-text">
            
            <small>                
                <Nav defaultActiveKey="/" as="ul" >
                    <Nav.Item as="li">
                        <Nav.Link disabled>BINERAL d.o.o.</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item as="li">
                        <Nav.Link disabled>Dolenji Boštanj 55, 8294 Boštanj</Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item as="li">
                        <Nav.Link disabled>MAT: 8807515000</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item as="li">
                        <Nav.Link disabled>DDV: 45735484</Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Nav.Link disabled>TR: SI56 6100 0002 5337 3294 pri DH d.d.</Nav.Link>
                    </Nav.Item>                    
                    
                </Nav>

                </small>                    
            </Navbar>
            </>
    }
}
export default Footer;