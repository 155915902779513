import React from 'react';
import { Placeholder, Navbar, Nav }  from 'react-bootstrap'; 
import { Container }  from 'react-bootstrap'; 

class Services extends React.Component {
    constructor(props)
    {
      super(props);
      
    }
    render() {
      return <Container fluid="xs" className="mainPage">
               <h1>STORITVE</h1>
               <p>Bineral kot partner pri obdelavi vaših podatkov
                   Čeprav imate na izbiro veliko možnosti, ni težko izbrati kdo najbolj ustreza vašim potrebam.</p> 

               <p>Mnoga podjetja obdelavo podatkov ohlapno uporabljajo za opisovanje vnosa in zajema podatkov. 
                  Naše storitve zajemajo celoten postopek zajemanja surovih podatkov, računalniške ali elektronske obdelave, 
                  pretvorbe, izdelave predikcijskih modelov in analize ter njihovo predstavitev v besedilni, tabelarni, vizualni obliki kot uporabne informacije.</p>

                <Container className="subPage">
                    <h3>Ponujamo različne storitve obdelave podatkov</h3>

                    <ul>
                        <li><strong>Modeli napovedovanja</strong></li>
                            S pomočjo nenehno posodabljanih in umerjenih modelov in s pomočjo natančnega, skladnega in hitrega pripisovanja, optimiziranja in napovedovanja spodbujajo poslovni učinek.
                            Pridobite popolno prepoznavnost vpliva notranjih in zunanjih dejavnikov poslovanja na prodajo in vlagajte v najučinkovitejše kombinacije odločitev.
                        <li><strong>Storitve standardizacije podatkov</strong></li> 
                            Učinkovito razumevanje, razlaga in organizacija vaših podatkv čistih in doslednih, ki jih lahko delite in uporabite v celotnem podjetju in pridobite dragocene vpoglede, ki vam pomagajo pri sprejemanju boljših odločitev.
                            <br/>
                        <li><strong>Upravljanje metapodatkov</strong></li>
                            Z našimi storitvami za upravljanje metapodatkov imate dostop do dragocenih podatkov, ki so kategorizirani in učinkovito sistematično obravnavani. 
                            Tako različnim oddelkom olajšate uporabo objektov metapodatkov za raznovrstne namene.
                        <li></li>
                    </ul>
                </Container>
                <br/>
                <p>Ne glede na to, ali obdelujete velike količine neobdelanih podatkov ali ste vključeni v majhne količine podatkov, veste, da sta pretvorba, obdelava podatkov in izdelava predikcijskih modelov ključnega pomena za vaše podjetje.
                  Z naraščajočimi plačami osebja, režijskimi stroški in infrastrukturnimi obremenitvami bi lahko bila vaša rešitev, da svoje potrebe po obdelavi podatkov in izdelavi predikcijskih modelov prepustite nam.</p>

            </Container>
    }
}
export default Services;