import React from 'react';
import './App.css';
import { Container, Row, Col }  from 'react-bootstrap'; 
import { BrowserRouter as Router, Route, Redirect, Switch, useHistory } from "react-router-dom";
import Footer from './components/Footer.js';
import Header from './components/Header.js';
import Home from './components/Home.js';
import Services from './components/Services.js';
import Products from './components/Products.js';
import Contact from './components/Contact.js';

class App extends React.Component {

  render() {
  return <Container fluid="md">
            <Router>
            <div className="App">
              <Row>
                <Header /> 
              </Row>
              <Container fluid="md" className="insideContent">
                <Switch>
                  <Route path="/" exact render={(props) => (<Home/>) }/>
                  <Route path="/services" exact render={(props) => (<Services/>) }/>
                  <Route path="/products" exact render={(props) => (<Products/>) }/>
                  <Route path="/contact" exact render={(props) => (<Contact/>) }/>
                </Switch>
              </Container>
              <Footer />
            </div>
            </Router>
         </Container>
  }
}

export default App;
