import React from 'react';
import { Placeholder, Navbar, Nav }  from 'react-bootstrap'; 
import { Accordion, Container }  from 'react-bootstrap'; 

class Contact extends React.Component {
    constructor(props)
    {
      super(props);
      this.state = {
        name: '',
        email: '',
        message: ''
      }
    }
    onNameChange(event) {
    this.setState({name: event.target.value})
    }

    onEmailChange(event) {
    this.setState({email: event.target.value})
    }

    onMessageChange(event) {
    this.setState({message: event.target.value})
    }
    resetForm(){
        this.setState({name: "", email: "", message: ""})
    }
    

    handleSubmit(e) {
        e.preventDefault();

        fetch('http://localhost:3002/send', {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            }).then((response) => (response.json()))
               .then((response)=> {
                if (response.status === 'success') {
                    alert("Message Sent.");
                    this.resetForm()
                } 
                else if(response.status === 'fail') {
                    alert("Message failed to send.")
                }
        }); 
    }
    render() {
        return <Container fluid="xs" className="mainPage">
               <h1>KONTAKT </h1>
                
                <Container className="subPage">
                    <h3>Bineral d.o.o.</h3>
                    <ul>
                         <li><strong>BINERAL - obdelava podatkov d.o.o.</strong></li>
                         <li><strong>Datum vpisa subjekta v sodni register</strong> 05.02.2021</li>
                         <li><strong>Matična številka</strong> 8807515000</li>
                         <li><strong>Ident. št. za DDV in davčna </strong> 45735484</li>
                         <li><strong>Skrajšana firma</strong> BINERAL d.o.o.</li>
                         <li><strong>Sedež</strong> Dolenji Boštanj</li>
                         <li><strong>Poslovni naslov</strong> Dolenji Boštanj 55, 8294 Boštanj, Slovenia</li>
                         <li><strong>Pravnoorganizacijska oblika</strong> Družba z omejeno odgovornostjo d.o.o</li>
                         <li><strong>TRR</strong> SI56 6100 0002 5337 3294 pri DH d.d.</li>
                         <li><strong>Elektronska pošta </strong> t (afna) bineral.com </li>
                         <li><strong>Elektronska pošta </strong> m (afna) bineral.com </li>
                    </ul>
                    <img src="./bineral-logo.png" /> 
                </Container>
                
                <br/>
                <p>
                   Smo goreči zagovorniki varovanja osebnih podatkov in na tem spletnem mestu ne shranjujemo nobenih podatkov o obiskovalcih, ne shranjujemo piškotov, klikov, ogledov, skratka ničesar.
                   Hvala za obisk. 
                </p>

            </Container>
    //   return <Container fluid="xs" className="mainPage">
    //            <h1>KONTAKT</h1>
    //            <Container>
                   
               
    //            <Accordion defaultActiveKey="0">
    //             <Accordion.Item eventKey="0">
    //                 <Accordion.Header>Osnovni podatki</Accordion.Header>
    //                 <Accordion.Body>
    //                 <p>
    //                 <ul>
    //                     <li><strong>BINERAL - obdelava podatkov d.o.o.</strong></li>
    //                     <li><strong>Datum vpisa subjekta v sodni register</strong> 05.02.2021</li>
    //                     <li><strong>Matična številka</strong> 8807515000</li>
    //                     <li><strong>Ident. št. za DDV in davčna </strong> 45735484</li>
    //                     <li><strong>Skrajšana firma</strong> BINERAL d.o.o.</li>
    //                     <li><strong>Sedež</strong> Dolenji Boštanj</li>
    //                     <li><strong>Poslovni naslov</strong> Dolenji Boštanj 55, 8294 Boštanj, Slovenia</li>
    //                     <li><strong>Pravnoorganizacijska oblika</strong> Družba z omejeno odgovornostjo d.o.o</li>
    //                     <li><strong>TRR</strong> SI56 6100 0002 5337 3294 pri DH d.d.</li>

    //                     <li><strong>Elektronska pošta </strong> t at bineral.com </li>
    //                     <li><strong>Elektronska pošta </strong> m at bineral.com </li>
                        
                        
    //                 </ul>
    //             </p>
    //                 </Accordion.Body>

    //                 <img src="./bineral-logo.png" />
    //             </Accordion.Item>
    //             { <Accordion.Item eventKey="1">
    //                 <Accordion.Header>Kontaktirajte nas</Accordion.Header>
    //                 <Accordion.Body>
    //                 <Container className="subPage">
    //                 <div className="App">
    //                     <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
    //                     <div className="form-group">
    //                         <label htmlFor="name">Name</label>
    //                         <input type="text" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} />
    //                     </div>
    //                     <div className="form-group">
    //                         <label htmlFor="exampleInputEmail1">Email address</label>
    //                         <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
    //                     </div>
    //                     <div className="form-group">
    //                         <label htmlFor="message">Message</label>
    //                         <textarea className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
    //                     </div>
    //                     <br/>
    //                     <button type="submit" className="btn btn-primary">Submit</button>
    //                     </form>
    //                 </div>
    //                 </Container>
    //                 </Accordion.Body>
    //             </Accordion.Item> }
    //             </Accordion>                   
               
    //            </Container>
               
    //         </Container>
    }
}
export default Contact;